import { countries, Country } from '@shared-types/countries';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export { countries, Country };

export const useCountryName = (language?: string) => {
  const [, i18n] = useTranslation();

  const countryMap = useMemo(
    () =>
      countries.reduce((acc, c) => {
        acc[c.name.toLowerCase()] = c;
        return acc;
      }, {} as Record<string, typeof countries[0]>),
    [countries],
  );

  const getLocalizedCountryName = useCallback(
    (c: typeof countries[number] | string, overrideLanguage?: string): string => {
      const langBase = overrideLanguage ?? language ?? i18n.language ?? 'en';
      const lang = langBase.includes('-')
        ? langBase.slice(0, langBase.indexOf('-'))
        : langBase;

      const countryInfo = typeof c === 'string' ? countryMap[c.toLowerCase()] : c;
      if (!countryInfo) {
        return c as string;
      }

      if (countryInfo.name === 'Holy See' && lang === 'de') {
        return 'Vatikanstadt';
      }

      return countryInfo.translations[lang] ?? countryInfo.name;
    },
    [language, i18n.language, countryMap],
  );

  return { getLocalizedCountryName };
};
