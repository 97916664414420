import * as Sentry from '@sentry/browser';
import classNames from 'classnames';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import invariant from 'tiny-invariant';

import linkResolver from '../../link-resolver';
import { CookieConsent } from '../components/cookie-consent';
import { sliceMap } from '../components/slices/index';
import { SliceProps } from '../components/slices/sliceprops';
import { PageDataQuery } from '../types/graphql';

import FatalError from '../components/fatal-error';
import Layout from './layout';

interface PageProps {
  data: PageDataQuery;
  pageResources: {
    page: {
      path: string;
    };
  };
}

interface PageState {
  eventId?: string;
  renderNetlifyAuthenticationWidget?: boolean;
}

declare global {
  interface Window {
    netlifyIdentity?: any;
    recaptchaOptions: { [k: string]: any };
  }
}

interface SliceBase {
  readonly __typename: string;
  readonly slice_label?: string;
  readonly type: string;
}

export class Page extends React.Component<PageProps, PageState> {
  state: PageState = {};

  componentDidCatch(error, errorInfo) {
    console.error('We caught an error in this page:');
    console.error({ error, errorInfo });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      scope.setExtra('page_data', this.props.data);
      scope.setTag('page_lang', this.props.data.page!.fields!.lang!);
      scope.setTag('page_uid', this.props.data.page!.fields!.uid!);
    });
    const eventId = Sentry.captureException(error);
    this.setState({ eventId });
  }

  componentDidUpdate() {
    window.recaptchaOptions = {
      lang: this.props.data.page!.fields!.lang,
    };
  }

  componentDidMount() {
    this.autoSwitchLanguage();
    this.mountNetlifyAuthWidget();
  }

  autoSwitchLanguage() {
    if (sessionStorage?.getItem('language_detected')) {
      return;
    }

    if (this.props.data.page!.fields!.lang !== 'de-de') {
      return;
    }

    const language: string =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      (navigator as any).userLanguage;

    if (!language) {
      return;
    }

    const page = this.props.data.allLanguages.nodes.find(
      (page) => page.fields.lang.slice(0, 2) === language.slice(0, 2),
    );

    if (!page) {
      return;
    }

    sessionStorage?.setItem('language_detected', '1');
    navigate(
      linkResolver({
        uid: this.props.data.page!.fields.uid,
        lang: this.props.data.page!.fields.lang,
      }),
    );
  }

  mountNetlifyAuthWidget() {
    const renderNetlifyAuthenticationWidget =
      typeof window !== 'undefined' &&
      typeof location !== 'undefined' &&
      Boolean(location.search || location.hash);

    if (!renderNetlifyAuthenticationWidget) {
      return;
    }

    this.setState({ renderNetlifyAuthenticationWidget });

    setTimeout(() => {
      if (!window.netlifyIdentity) {
        return;
      }

      window.netlifyIdentity.on('init', (user) => {
        if (!user) {
          window.netlifyIdentity.on('login', () => {
            document.location.href = '/admin/';
          });
        }
      });
    });
  }

  render() {
    const { data, pageResources } = this.props;

    if (this.state.eventId) {
      return (
        <FatalError
          sentryOptions={{
            eventId: this.state.eventId,
            lang: data.page?.fields?.lang || undefined,
          }}
        />
      );
    }

    invariant(
      data.page?.frontmatter?.__typename === 'PageFrontmatter',
      `need a page, not a ${data.page?.__typename}/${data.page?.frontmatter?.__typename}`,
    );

    const alternateLanguages = data.allLanguages.nodes.map(
      ({ fields: { lang }, id }) => ({ id, lang, uid: data.page?.fields.uid }),
    );

    const availableSlices = new Set(Object.keys(sliceMap));

    return (
      <Layout
        allowedRoles={data.page.frontmatter?.allowed_roles}
        alternateLanguages={alternateLanguages}
        currentPage={data.page!}
        footerData={data.footer || data.defaultFooter!}
        headerData={data.header || data.defaultHeader!}
      >
        <Helmet>
          <html lang={data.page!.fields!.lang!} />

          <meta charSet="utf-8" />
          <title>
            {data.page!.frontmatter!.seo_title ||
              `${data.page!.frontmatter!.title || 'N/A'} - DEKEMA online`}
          </title>
          <meta
            name="description"
            content={data.page.frontmatter!.seo_description || ''}
          />

          {alternateLanguages.map((lang) => (
            <link
              key={lang!.id!}
              rel="alternate"
              hrefLang={lang!.lang!}
              href={
                data.site!.siteMetadata!.siteUrl +
                linkResolver({ uid: lang!.uid!, lang: lang!.lang! })
              }
            />
          ))}

          <meta property="og:locale" content={data.page.fields!.lang!} />

          {this.state.renderNetlifyAuthenticationWidget && (
            <script src="https://identity.netlify.com/v1/netlify-identity-widget.js" />
          )}
        </Helmet>

        <CookieConsent
          content={data.cookieConsent || data.defaultCookieConsent!}
          lang={data.page.fields.lang}
        />

        <main>
          {(data.page?.frontmatter?.slices as unknown as SliceBase[])?.map(
            (slice, i) => (
              <section className={classNames(slice.type, slice.slice_label)} key={i}>
                {availableSlices.has(slice.type) ? (
                  React.createElement<SliceProps<any>>(sliceMap[slice.type], {
                    basepath: (pageResources && pageResources.page.path) || '',
                    data: slice,
                    lang: data!.page!.fields!.lang,
                  })
                ) : (
                  <pre>{slice.type} not found</pre>
                )}
              </section>
            ),
          )}
        </main>
      </Layout>
    );
  }
}

export default Page;

export const query = graphql`
  query PageData($id: String!, $lang: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allLanguages: allMarkdownRemark(
      filter: { fields: { collection: { eq: "pages" }, uid: { eq: $id } } }
    ) {
      nodes {
        id
        fields {
          lang
        }
      }
    }

    page: markdownRemark(
      fields: { collection: { eq: "pages" }, lang: { eq: $lang }, uid: { eq: $id } }
    ) {
      fields {
        collection
        lang
        uid
      }
      frontmatter {
        ... on PageFrontmatter {
          __typename
          allowed_roles
          seo_description
          seo_image {
            publicURL
          }
          seo_title
          title

          slices {
            ... on AccessoryListSlice {
              __typename
              type

              title {
                html
              }
              body {
                html
              }
              products
            }

            ... on Austromat220Slice {
              __typename
              type

              austromat_220_entries {
                body {
                  html
                }
                image {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 900)
                  }
                  publicURL
                }
                image_alt
                title {
                  html
                }
              }
              slice_label
            }

            ... on BannerSlice {
              __typename
              type

              ar {
                ar_cta_text
                ar_model_glb {
                  publicURL
                }
                ar_model_usdz {
                  publicURL
                }
              }
              banner_image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              banner_image_alt
              title {
                html
              }
              body {
                html
              }
              links {
                ...Link
              }
              ope {
                ope_aspect_ratio
                ope_cta_text
                ope_link
              }
              scroll_down_text
              slice_label
              video_popup {
                video_popup_link
                video_popup_title
              }
              video {
                banner_video_mp4 {
                  publicURL
                }
                banner_video_webm {
                  publicURL
                }
                banner_video_ogg {
                  publicURL
                }
              }
            }

            ... on CarouselSlice {
              __typename
              type

              auto_rotate
              rotation_duration
              pages {
                page_title {
                  html
                }
                carousel_image_w1 {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                carousel_image_w2 {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                carousel_image_w3 {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                carousel_image_alt
                carousel_page_links {
                  ...Link
                }
                subtitle {
                  html
                }
                subsubtitle {
                  html
                }
                video {
                  carousel_video_mp4 {
                    publicURL
                  }
                  carousel_video_webm {
                    publicURL
                  }
                  carousel_video_ogg {
                    publicURL
                  }
                }
              }
              slice_label
            }

            ... on ContactSlice {
              __typename
              type

              title {
                html
              }
              error_text
              fields {
                field_required
                field_type
                label
                options
                placeholder
              }
              form_name
              please_wait_text
              submit_text
              success_text
            }

            ... on ContentSlice {
              __typename
              type

              body {
                html
              }
              slice_label
            }

            ... on CountdownSlice {
              __typename
              type

              body {
                html
              }
              countdown_time
              title {
                html
              }
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              image_alt
              links {
                ...Link
              }
            }

            ... on CustomMapSlice {
              __typename
              type

              geopoints {
                body {
                  html
                }
                lat
                lng
              }
            }

            ... on FeatureListSlice {
              __typename
              type

              feature_list_entries {
                body {
                  html
                }
                title {
                  html
                }
              }
              title {
                html
              }
            }

            ... on GoldAustromatSlice {
              __typename
              type
            }

            ... on IconListSlice {
              __typename
              type

              icon_list_entries {
                alt
                body {
                  html
                }
                icon {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 304)
                  }
                }
                link {
                  ...Link
                }
                title {
                  html
                }
              }
              slice_label
              title {
                html
              }
            }

            ... on ImageSlice {
              __typename
              type

              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              image_alt
              max_width
            }

            ... on PartnerListSlice {
              __typename
              type

              choose_country_text
              our_partners_text
              sales_partner_icon {
                childImageSharp {
                  gatsbyImageData(width: 64)
                }
                publicURL
              }
              sales_partner_text
              service_partner_icon {
                childImageSharp {
                  gatsbyImageData(width: 64)
                }
                publicURL
              }
              service_partner_text
            }

            ... on PartnerMapSlice {
              __typename
              type
            }

            ... on ProductListSlice {
              __typename
              type

              anchor_id
              contact_text
              contact_link {
                ...Link
              }
              product_links {
                product
              }
              title {
                html
              }
              show_products_text
              slice_label
            }

            ... on QuoteSlice {
              __typename
              type

              quote_author
              quote_text {
                html
              }
              portrait_image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1120)
                }
              }
              portrait_image_alt
              portrait_video {
                fade_in_offset
                portrait_video_mp4 {
                  publicURL
                }
                portrait_video_webm {
                  publicURL
                }
                portrait_video_ogg {
                  publicURL
                }
              }
              show_quotation_marks
              slice_label
            }

            ... on ServiceSlice {
              __typename
              type

              dealer_icon {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 64)
                }
              }
              dealer_text {
                html
              }
              dealer_title {
                html
              }
              hide_login_icon
              lab_icon {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 64)
                }
              }
              lab_text {
                html
              }
              lab_title {
                html
              }
              login_icon {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 64)
                }
              }
              login_text {
                html
              }
              login_title {
                html
              }
              subtitle {
                html
              }
              title {
                html
              }
            }

            ... on SplitListSlice {
              __typename
              type

              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1000)
                }
              }
              split_list_entries {
                body {
                  html
                }
              }
              title {
                html
              }
              slice_label
              video {
                ogg {
                  publicURL
                }
                webm {
                  publicURL
                }
                mp4 {
                  publicURL
                }
              }
            }

            ... on TwoWayBannerSlice {
              __typename
              type

              cta_text
              cta_link {
                ...Link
              }
              images {
                alt_text
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              text {
                html
              }
            }

            ... on TimelineSlice {
              __typename
              type

              timeline_entries {
                body {
                  html
                }
                title {
                  html
                }
                year
              }
              title {
                html
              }
            }
          }
        }
      }
    }
    header: markdownRemark(
      fields: { collection: { eq: "header" }, lang: { eq: $lang } }
    ) {
      ...Header
    }
    footer: markdownRemark(
      fields: { collection: { eq: "footer" }, lang: { eq: $lang } }
    ) {
      ...Footer
    }
    cookieConsent: markdownRemark(
      fields: { collection: { eq: "cookie-consent" }, lang: { eq: $lang } }
    ) {
      ...CookieConsent
    }
    defaultHeader: markdownRemark(
      fields: { collection: { eq: "header" }, lang: { eq: "en" } }
    ) {
      ...Header
    }
    defaultFooter: markdownRemark(
      fields: { collection: { eq: "footer" }, lang: { eq: "en" } }
    ) {
      ...Footer
    }
    defaultCookieConsent: markdownRemark(
      fields: { collection: { eq: "cookie-consent" }, lang: { eq: "en" } }
    ) {
      ...CookieConsent
    }
  }
`;
